export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"href":"https://auth-worker.integration.rialtic.app","rel":"preconnect"},{"href":"https://api-worker.integration.rialtic.app","rel":"preconnect"},{"href":"/favicon.svg","rel":"icon","type":"image/svg+xml"},{"href":"/favicon.png","rel":"icon","type":"image/png"},{"href":"https://cdn.jsdelivr.net","rel":"preconnect"},{"href":"https://cdn.jsdelivr.net/npm/@unocss/reset/tailwind.min.css","rel":"stylesheet"},{"href":"/favicon.svg","rel":"icon","type":"image/svg+xml"},{"href":"/favicon.png","rel":"icon","type":"image/png"},{"href":"https://cdn.jsdelivr.net","rel":"preconnect"}],"style":[],"script":[],"noscript":[],"title":"Rialtic Console","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false